export class ElectricityConsentModel {

  public hasElecCommunicatingMeter = false
  public activateLoadCurve= false

  public userIsEnergyCheckBeneficiary= false

  public hasBoxAndWifi?: boolean
  public wifiNearMeter?: boolean
  public hasCompatibleDevice?: boolean

  public wantBillableOption= false
  public acceptOptionConditions?: boolean

  constructor() {

  }


}

