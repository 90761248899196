import {TitulaireInfosDto} from "./TitulaireInfosDto";
import {RequestedServiceDto} from "./RequestedServiceDto";


export class SubscriptionDto {

  constructor(
    public requestedServices: RequestedServiceDto[],
    public genericConsent: boolean,
    public titulaireInfos:TitulaireInfosDto,
    public refContrat: string,
    public refEdl:string) {
  }
  
}
