import {Component, OnDestroy, ViewChild} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {FormsModule, NgForm} from "@angular/forms";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {SubscriptionFormService} from "../../services/subscription-form.service";
import {RegistrationModel} from "../../models/RegistrationModel";
import {UserTypes} from "../../models/UserTypes.enum";
import {catchError, finalize, of, Subject, takeUntil} from "rxjs";
import {NotEmptyDirective} from "../../directives/not-empty.directive";
import {LoadingService} from "../../services/loading.service";
import {AlertModule} from "ngx-bootstrap/alert";
import {ContractDto} from "../../models/dto/ContractDto";
import {ConsentComponent} from "../../components/consents/elec-consent/elec-consent.component";
import {GasConsentComponent} from "../../components/consents/gas-consent/gas-consent.component";
import {EnergyType} from "../../models/EnergyType";
import {PopoverModule} from "ngx-bootstrap/popover";
import {RgpdConsentComponent} from "../../components/consents/rgpd-consent/rgpd-consent.component";
import {PointDeServiceDTO} from "../../models/dto/PointDeServiceDTO";
import {SubscribeErrorType} from "../../models/dto/subscribe-error.type";

type AlertType = { type: string; msg: string; };

@Component({
  selector: 'app-register-form',
  standalone: true,
  imports: [RouterOutlet, FormsModule, NgIf, NgClass, ConsentComponent, NotEmptyDirective, AlertModule, NgForOf, ConsentComponent, GasConsentComponent, PopoverModule, RgpdConsentComponent],
  templateUrl: './register-form.component.html',
  styleUrl: './register-form.component.scss'
})
export class RegisterFormComponent implements OnDestroy {
  @ViewChild('registerForm') registerForm?: NgForm
  registrationModel: RegistrationModel = new RegistrationModel();

  //used as input for child components
  elecConsentResetNeeded = false;
  gasConsentResetNeeded = false;

  //linked to data retrieved from EFluid API
  referencesFoundInEfluid = false;
  errorInReferences = false;
  gasAvailable?: boolean = undefined;
  elecAvailable?: boolean = undefined;

  alert?: AlertType | null;

  private destroy$ = new Subject<void>();
  protected readonly UserTypes = UserTypes;
  protected readonly EnergyType = EnergyType;
  errorSmartMeter: boolean = false;

  // TODO : remove when gas available
  errorInEnergies: boolean = false;

  constructor(private subscriptionFormService: SubscriptionFormService, private loadingService: LoadingService) {
  }

  onSubmit() {
    if (this.registerForm?.valid && this.referencesFoundInEfluid) this.HandleRegistration();
    else this.registerForm?.form.markAllAsTouched()
  }

  OnFormReset() {
    this.resetComponentBool();
    this.resetModel(); // needed because some properties comes from efluid and not from form;
    this.registerForm?.reset(); //needed to reset form to "ng-untouched state"
  }

  onEnergiesChanges(energy: EnergyType | undefined) {
    switch (energy) {
      case EnergyType.Gas:
        // in case the user has previously check the elec consent
        this.registrationModel.electricityConsentModel = undefined
        break;
      case EnergyType.Electricity:
        // in case the user has previously check the gas consent
        this.registrationModel.gasConsentModel = undefined
        break;
      case undefined:
        this.registrationModel.electricityConsentModel = undefined
        this.registrationModel.gasConsentModel = undefined
        break;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  OnSearchReferences() {
    this.resetOnSearchReferences();
    this.LoadDataFromReferences();
  }

  private resetOnSearchReferences() {

    //reset component state
    this.resetComponentBool();
    // reset model but for the user type already selected by user
    let selectedUserType = (this.registerForm?.form.get('UserType')?.value) as UserTypes;
    this.resetModel();
    this.registrationModel.userType = selectedUserType;
    //clear form if previous data loaded
    this.registerForm?.form.get('UserInfo')?.reset();
  }

  private LoadDataFromReferences() {
    let contractRef = this.registerForm?.form.get('References.ContractReference')?.value;
    let edl = this.registerForm?.form.get('References.ReferenceEDL')?.value;

    this.LoadUserInfo(contractRef, edl).pipe(
      catchError(() => {
        this.displayError("Oups, une erreur est survenue");
        return of(null)
      }),
      takeUntil(this.destroy$)
    ).subscribe({
      next: (data) => {
        this.HandleUserInfo(data);
        this.loadingService.loadingOff()
      },
      error: () => this.loadingService.loadingOff()
    });
  }

  private LoadUserInfo(contractRef: string, EDLRef: string) {
    this.loadingService.loadingOn();
    return this.registerForm?.form.get('References')?.valid ? this.subscriptionFormService.GetDataFromEFluid(contractRef, EDLRef).pipe(
        finalize(() => this.loadingService.loadingOff()),
        catchError((err: { status: any, message: string }) => {
          if (err.status === 404) this.errorInReferences = true;
          else {
            this.displayError(err.message)
          }
          return of(null)
        })
      )
      : of(null)
  }

  private HandleUserInfo(data: ContractDto | null) {
    if (!data) return;
    this.referencesFoundInEfluid = true;
    var energies = this.getEnergyType(data.edl?.pointsDeService ?? [])
    this.gasAvailable = energies === EnergyType.Both || energies === EnergyType.Gas;
    this.elecAvailable = energies === EnergyType.Both || energies === EnergyType.Electricity;
    this.fulfillUserInfoForm(data, energies);
    this.storeUserInfoInModel(data, energies);
  }

  private fulfillUserInfoForm(data: ContractDto, energies?: EnergyType) {
    setTimeout(() => {
      this.registerForm!.form.patchValue(
        {
          UserInfo: {
            FirstName: data.titulaire?.prenom,
            LastName: data.titulaire?.nom,
            AddressNumber: data.titulaire?.adresse?.numero,
            Street: data.titulaire?.adresse?.rue,
            PostCode: data.titulaire?.adresse?.codePostal,
            City: data.titulaire?.adresse?.commune,
            Email: data.titulaire?.email,
            PhoneNumber: data.titulaire?.telephoneMobile,
            Energies: energies
          }
        }
      )
    });
  }

  private storeUserInfoInModel(data: ContractDto, energies?: EnergyType) {

    this.registrationModel.userInfo.firstName = data.titulaire?.prenom ?? "";
    this.registrationModel.userInfo.lastName = data.titulaire?.nom ?? "";
    this.registrationModel.userInfo.street = data.titulaire?.adresse?.rue ?? "";
    this.registrationModel.userInfo.number = data.titulaire?.adresse?.numero ?? "";
    this.registrationModel.userInfo.postCode = data.titulaire?.adresse?.codePostal ?? "";
    this.registrationModel.userInfo.city = data.titulaire?.adresse?.commune ?? "";
    this.registrationModel.userInfo.email = data.titulaire?.email ?? "";
    this.registrationModel.userInfo.phoneNumber = data.titulaire?.telephoneMobile ?? "";
    this.registrationModel.userInfo.energies = energies;

    //references stored in model only if match with data in EFluid
    let EDLValidatedInput = this.registerForm!.form.get('References.ReferenceEDL')!.value;
    let ContratValidatedInput = this.registerForm!.form.get('References.ContractReference')!.value;
    this.registrationModel.ReferenceEDL = EDLValidatedInput;
    this.registrationModel.ContractReference = ContratValidatedInput;

  }

  private getEnergyType(pds: PointDeServiceDTO[]): EnergyType | undefined {

    let elecPds = this.FindElectricityMeter(pds);
    let gasPds = this.FindGasMeter(pds)
    let hasElecMeter = elecPds !== undefined;
    let hasGasMeter = gasPds !== undefined;

    // TODO: verifiy and uncomment when gas option is activated
    // if(hasElec && elecPds.communicantActif){
    //   if(hasGas && gasPds.communicantActif) return EnergyType.Both
    //   else return EnergyType.Electricity
    // }
    // if(hasGas && gasPds.communicantActif) return EnergyType.Gas
    // return undefined;

    //TODO: remove when gas option is activated

    if (elecPds !== undefined && elecPds.communicantActif) return EnergyType.Electricity

    //Right now, subscription only available for elec - without elec, no subscription available
    if (hasElecMeter === undefined) this.errorInEnergies = true;

    //Can subscribe only if smart meter
    if (elecPds !== undefined && !elecPds.communicantActif) this.errorSmartMeter = true;
    return undefined;

  }

  private FindElectricityMeter(pds: PointDeServiceDTO[]): PointDeServiceDTO | undefined {
    return pds.find(pds => pds.Type?.toLowerCase() === "électricité");
  }

  private FindGasMeter(pds: PointDeServiceDTO[]): PointDeServiceDTO | undefined {

    return pds.find(pds => pds.Type?.toLowerCase() === "gaz");
  }

  private HandleRegistration() {
    this.loadingService.loadingOn();
    try {
      this.subscriptionFormService.Register(this.registrationModel)
        .pipe(
          finalize(() => this.loadingService.loadingOff()),
          takeUntil(this.destroy$)
        )
        .subscribe({
          next: () => {
            this.loadingService.loadingOff();
            this.alert = {type: 'success', msg: "Votre souscription a bien été envoyée"}
            this.OnFormReset();
          },
          error: (err: { status: number, errorType: SubscribeErrorType }) => {
            this.displayError(this.GetSubscribeErrorMessage(err.errorType));
            if(err.errorType === SubscribeErrorType.NotSmartMeter){
              console.log('smart meter error');
              this.OnFormReset();
            }
          }
        })
    } catch (e) {
      this.loadingService.loadingOff();
      this.displayError("Une erreur s'est produite, votre souscription n'a pas pu être envoyée");
    }
  }

  private resetModel() {
    this.registrationModel = new RegistrationModel()
    // no need to reset the children consents here
    // as the model is instantiated, the ngIf for the children components are false
    //when references are found, becomes true => onInit() children => children consents are reset()
  }

  private resetComponentBool() {
    this.referencesFoundInEfluid = false;
    this.errorInReferences = false;
    this.gasAvailable = undefined;
    this.elecAvailable = undefined;
    this.errorInEnergies = false;
    this.errorSmartMeter = false;
  }

  private displayError(message: string) {
    this.alert = {
      type: 'danger',
      msg: message
    }
  }

  protected readonly undefined = undefined;

  //client has changes his mind
  private getFullAdress(rue: string | undefined, numero: string | undefined) {
    return (rue + " " + numero).trim();
  }

  private GetSubscribeErrorMessage(errorType: SubscribeErrorType): string {
    var message = "";
    switch (errorType) {
      case SubscribeErrorType.NoActiveContractFound :
        message += "Aucun contrat actif n'a été trouvé."
        break;
      case SubscribeErrorType.UpdateContactFailed :
        message += "Vos données de contact n'ont pas pu être mises à jour."
        break;
      case SubscribeErrorType.NotSmartMeter :
        message = "Le déploiement des compteurs communicants est en cours. Vous n’êtes pas encore équipé d’un compteur communicant, ce qui ne vous permet pas d’être éligible à notre service Ma conso énergie. Ce service vous sera accessible dès l’installation d’un compteur communicant."
        break;
      default:
        message += "Une erreur s'est produite.";
    }
    if(errorType !== SubscribeErrorType.NotSmartMeter)message += "Votre souscription n'a pas pu être envoyée.";
    return message;
  }

  OnEnter() {

    if (this.registerForm?.valid && this.referencesFoundInEfluid) this.onSubmit();

    else if (this.registerForm?.form.get('References')?.valid) this.OnSearchReferences();

  }
}
