import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";

@Directive({
  selector: '[notEmptyValidator]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: NotEmptyDirective,
    multi: true
  },],
  standalone: true
})
export class NotEmptyDirective implements Validators {
  validate(control: AbstractControl): ValidationErrors | null {
    return this.noEmptyInputValidator()(control)
  }

  noEmptyInputValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const valid = control.value !== undefined && control.value !== null && control.value !== '' && (control.value).trim() !== "";
      return valid ? null : {noEmptyInputValidator: {valid: false}};

    };
  }
}
