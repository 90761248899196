import {Component} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {LoadingIndicatorComponent} from "./components/loading-indicator/loading-indicator.component";
import {environment} from "../environments/environment";
import {HeaderComponent} from "./components/header/header.component";
import {FooterComponent} from "./components/footer/footer.component";


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, LoadingIndicatorComponent, HeaderComponent, FooterComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {

  constructor() {}
  title = 'Ma Conso Énergie | Vialis';

}
