<header id="header" role="header">
  <div class="top-header"></div>
  <div class="container-wrapper">
    <div class="logo-slogan">
      <div class="logo-wrapper">
        <div class="logo">
          <img src="assets/logo_Vialis.svg" alt="logo-vialis">
        </div>
      </div>
      <div class="slogan">
        <h1> Ma Conso Énergie</h1>
      </div>
    </div>
  </div>
</header>
