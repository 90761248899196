<!-- TODO liens des conditions générales!!!!-->
<section id="elec-consent">
  <label class="form-label big">Conditions nécessaires au fonctionnement du service (Électricité):</label>
  <div ngModelGroup="elec-consent-group">
    <div id="chequeEnergieConsentement" *ngIf="userType===UserTypes.ChequeEnergie">
      <div class="from-group form-check">
        <input [ngClass]="beneficiaire.touched && beneficiaire.invalid? 'border-danger':'border-primary'" type="checkbox" class="form-check-input"
               id="beneficiaire" name="beneficiaire" #beneficiaire="ngModel"
               [(ngModel)]="electricityConsent.userIsEnergyCheckBeneficiary" (ngModelChange)="electricityConsentChange.emit(electricityConsent)" required>
        <label class="form-check-label" for="beneficiaire">
          <span class="required">*</span> Je suis bénéficiaire du chèque énergie.
        </label>
      </div>
      <div class="consoTempsReelChequeEnergie">
        <ng-container *ngTemplateOutlet="consoTempsReelConsent"></ng-container>
      </div>
    </div>

    <div id="consentCommon">
      <div class="from-group form-check">
        <input [ngClass]="elecCommunicatingMeter.touched && elecCommunicatingMeter.invalid? 'border-danger':'border-primary'" type="checkbox" class="form-check-input" id="elecCommunicatingMeter"
               name="elecCommunicatingMeter" #elecCommunicatingMeter="ngModel" (ngModelChange)="electricityConsentChange.emit(electricityConsent)"
               [(ngModel)]="electricityConsent.hasElecCommunicatingMeter" required>
        <label class="form-check-label" for="elecCommunicatingMeter">
          <span class="required">*</span> Je suis équipé(e) d'un compteur d'électricité communicant n'ayant pas de scellé empêchant d'ôter son capot.
          <div class="d-inline">
            <button class="tooltip-button" type="button" [popover]="communicatingMeterInfos" containerClass="tooltip-content" triggers="focus" placement="top">
              <img src="assets/infobulle.svg" alt="help-icon">
            </button>
            <ng-template #communicatingMeterInfos>
              <p>Si un scellé empêche de retirer le capot, merci de prendre contact avec votre Gestionnaire de Réseau de Distribution (GRD) d’électricité en lui indiquant que vous souhaitez installer un
                émetteur radio.</p>
            </ng-template>
          </div>
        </label>


      </div>

      <div class="from-group form-check">
        <input type="checkbox" class="form-check-input border-primary" id="activateLoadCurve"
               name="activateLoadCurve"
               (ngModelChange)="electricityConsentChange.emit(electricityConsent)" [(ngModel)]="electricityConsent.activateLoadCurve">
        <label class="form-check-label" for="activateLoadCurve">
          J'autorise Vialis à demander l'activation de ma courbe de charge auprès du Gestionnaire de Réseau de Distribution (GRD) et à en récupérer les données.
        </label>
      </div>

    </div>

    <div id="OptService" class="mt-4" *ngIf="userType!==UserTypes.ChequeEnergie">
      <div>
        <p class="fw-light fst-italic">Le service « Ma Conso Énergie », en mode standard, permet de suivre les données de consommation, les données les plus récentes étant celles de la veille (service gratuit).</p>
      </div>
      <div class="conditions-options">En option:</div>
      <div class="from-group form-check">
        <input type="checkbox" class="form-check-input border-primary"
               id="consoTempsReelOpt" name="consoTempsReelOpt" [(ngModel)]="electricityConsent.wantBillableOption"
               (ngModelChange)="onBillableOptionChange()">
        <label class="form-check-label" for="consoTempsReelOpt">
          Pour 2€ TTC / mois je souhaite accéder à ma consommation en temps réel en installant un émetteur radio sur mon compteur communicant.
        </label>
      </div>
      <div class="consoTempsReelProParticulier" *ngIf="electricityConsent.wantBillableOption">
        <ng-container *ngTemplateOutlet="consoTempsReelConsent"></ng-container>
      </div>
    </div>

    <ng-template #consoTempsReelConsent>
      <div class="consoTempsReelConsent">
        <div class="from-group form-check">
          <input [ngClass]="box.touched && box.invalid? 'border-danger':'border-primary'" type="checkbox" class="form-check-input" id="box" name="box"
                 #box="ngModel" [(ngModel)]="electricityConsent.hasBoxAndWifi" required (ngModelChange)="electricityConsentChange.emit(electricityConsent)">
          <label class="form-check-label" for="box">
            <span class="required">*</span> Je suis équipé(e) d’une box internet connectée et d’un réseau Wifi.
          </label>
        </div>
        <div class="from-group form-check">
          <input [ngClass]="wifi.touched && wifi.invalid? 'border-danger':'border-primary'" type="checkbox" class="form-check-input" id="wifi" name="wifi"
                 #wifi="ngModel" [(ngModel)]="electricityConsent.wifiNearMeter" required (ngModelChange)="electricityConsentChange.emit(electricityConsent)">
          <label class="form-check-label" for="wifi">
            <span class="required">*</span> Je réceptionne le signal Wifi de ma box à proximité de mon compteur électrique.
          </label>
        </div>
        <div class="from-group form-check">
          <input [ngClass]="device.touched && device.invalid? 'border-danger':'border-primary'" type="checkbox" class="form-check-input" id="device"
                 name="device" #device="ngModel" [(ngModel)]="electricityConsent.hasCompatibleDevice" required (ngModelChange)="electricityConsentChange.emit(electricityConsent)">
          <label class="form-check-label" for="device">
            <span class="required">*</span> Je possède un smartphone ou une tablette compatible iOS et Android à l'installation de l'application « Ma Conso Énergie »
            et doté d'une connexion internet via le réseau Wifi.
          </label>
        </div>
      </div>
      <div class="from-group form-check">
        <input [ngClass]="optionConditions.touched && optionConditions.invalid? 'border-danger':'border-primary'" type="checkbox" class="form-check-input"
               id="optionConditions" name="optionConditions" [(ngModel)]="electricityConsent.acceptOptionConditions"
               (ngModelChange)="electricityConsentChange.emit(electricityConsent)" #optionConditions="ngModel" required>
        <label class="form-check-label" for="optionConditions">
          <span class="required">*</span> Je reconnais avoir pris connaissance des
          <a class="fw-bold text-black" target="_blank" href="https://energies.vialis.net/sites/default/files/domain_vialis_energies/pdf/cp-ma-conso-energie.pdf">conditions particulières</a>
          et les accepter. Ma souscription sera effective à la validation de ce formulaire.
        </label>
      </div>
    </ng-template>
  </div>
</section>


