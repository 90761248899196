
<div class=" container-wrapper">
  <!-- Form description -->
  <section id="form-description">
    <p class="text-description m-0"> Avec le service Ma Conso Énergie de Vialis, je dispose d'un suivi au plus près de mes consommations d'énergies en € et en kWh !
      <br/>Je souhaite bénéficier du service Ma Conso Énergie :</p>
    <span class="text-description m-0"> </span>
  </section>
  <form id="form" (ngSubmit)="onSubmit()" #registerForm="ngForm" (keyup.enter)="OnEnter()">
    <div class="form-wrapper">
      <section id="notifications" class="overlayAll" *ngIf="alert">
        <div class="notification middlePage mx-auto">
          <alert [type]="alert.type" [dismissible]="true" (onClosed)="alert=null">{{ alert.msg }}</alert>
        </div>
      </section>
      <section class="row row-user-type-section">
        <div class="col-sm-8 offset-md-2">
          <label class="form-label big">Je suis<span class="required">*</span></label>
          <!-- Particulier -->
          <div class="form-check">
            <input id="particulier" class="form-check-input" [ngClass]="UserType.invalid? 'border-danger':'border-primary'"
                   type="radio" name="UserType" #UserType="ngModel" [value]="UserTypes.Particulier"
                   [(ngModel)]="registrationModel.userType" (ngModelChange)="elecConsentResetNeeded=true" required>
            <label class="form-check-label fw-bold" for="particulier">Un particulier</label>
          </div>
          <!-- Particulier cheque energie -->
          <div class="form-check">
            <input id="chequeEnergie" class="form-check-input" [ngClass]="UserType.invalid? 'border-danger':'border-primary'"
                   type="radio" name="UserType" #UserType="ngModel" [value]="UserTypes.ChequeEnergie"
                   [(ngModel)]="registrationModel.userType" (ngModelChange)="elecConsentResetNeeded=true" required>
            <label class="form-check-label" for="chequeEnergie"><strong>Un particulier</strong> et je bénéficie du chèque énergie</label>
          </div>
          <!-- Professionnel -->
          <div class="form-check">
            <input id="pro" class="form-check-input" [ngClass]="UserType.invalid? 'border-danger':'border-primary'"
                   type="radio" name="UserType" #UserType="ngModel" [value]="UserTypes.Pro"
                   [(ngModel)]="registrationModel.userType" (ngModelChange)="elecConsentResetNeeded = true;" required>
            <label class="form-check-label fw-bold" for="pro">Un professionnel</label>
            <button class="tooltip-button" type="button"  [popover]="professionalRules" containerClass="tooltip-content" container="container" triggers="focus" placement="bottom">
              <img src="assets/infobulle.svg" alt="help-icon">
            </button>
            <ng-template #professionalRules>
              <p class="fw-bold"> Sont éligibles au service, les clients :</p>
              <p> - Avec une puissance électrique souscrite < ou = à 36 kVA.</p>
              <p class="fw-bold"> et/ou</p>
              <p> - Avec une CAR (Consommation Annuelle de Référence) de gaz inférieure à 300 000 kWh.</p>
            </ng-template>
          </div>
        </div>
      </section>
      <!-- END section-user-type -->

      <div ngModelGroup="References" id="references" #references="ngModelGroup">

        <section class="references-elec-gaz row">
          <div class="col-sm-2 form-label-wrapper">
            <!-- TODO: add « ou gaz » when available -->
            <label class="form-label" for="ContractReference"> Référence contrat électricité<span class="required">*</span></label>
          </div>
          <div class="col-sm-8 field-with-tooltip">
            <input id="ContractReference" class="form-control"
                   [ngClass]="((ContractReference.touched && ContractReference.invalid) || errorInReferences)? 'is-invalid' : (referencesFoundInEfluid?'is-valid':'')"
                   type="text" name="ContractReference" #ContractReference="ngModel" ngModel notEmptyValidator>
            <button class="tooltip-button" type="button" containerClass="tooltip-content" [popover]="contractExample" content="img"  triggers="focus" placement="top">
              <img src="assets/infobulle.svg" alt="help-icon">
            </button>
            <ng-template #contractExample>
                <img src="assets/elec-part-annuel_OPT.svg" class="tooltip-img" alt="contract-example">
            </ng-template>
          </div>
        </section>
        <!-- END references-elec-gaz -->

        <section class="reference-edl row">
          <div class="col-sm-2 form-label-wrapper">
            <label class="form-label" for="ReferenceEDL">Référence EDL <span class="required">*</span> </label>
          </div>
          <div class="col-sm-8 field-with-tooltip">
            <input id="ReferenceEDL" class="form-control me-1" [ngClass]="((ReferenceEDL.touched && ReferenceEDL.invalid)
                       || errorInReferences)?'is-invalid':(referencesFoundInEfluid?'is-valid' :'')"
                   type="text" name="ReferenceEDL" #ReferenceEDL="ngModel" ngModel notEmptyValidator>
            <button type="button" class="tooltip-button" [popover]="edlExample" containerClass="tooltip-content" triggers="focus" placement="top">
              <img src="assets/infobulle.svg" alt="help-icon">
            </button>
            <ng-template #edlExample>
              <img src="assets/elec-part-annuel.svg" class="tooltip-img" alt="contract-example">
            </ng-template>
          </div>
          <div class="col-sm-8 offset-md-2">
            <div *ngIf="errorInReferences" class="error-msg my-2">
              Anomalie: il doit y avoir une erreur dans les références indiquées.
              Elles ne correspondent pas entre elles. Veillez à bien vous référer aux éléments indiqués sur votre facture.
              Si le problème persiste, n’hésitez pas à nous contacter.
            </div>
            <div class="form-actions references-action text-center my-3">
              <button type="button" id="btn-search-references" class="btn bg-primary" [disabled]="!references.valid"
                      (click)="OnSearchReferences()">Vérifier mes références</button>
            </div>
          </div>
        </section>

        <!-- TODO: remove 'errorInEnergiesAvailable' condition when gas option is activated-->
        <div *ngIf="errorInEnergies" class="text-danger mb-4 col-sm-8 offset-md-2">
          Anomalie: le service Ma Conso Énergie n'est actuellement disponible que pour l'électricité. Il semble que vous ne bénéficiez actuellement pas d'une fourniture d'électricité chez Vialis.
          N'hésitez-pas à contacter notre service client pour davantage d'informations.
        </div>

        <div *ngIf="errorSmartMeter" class="text-danger mb-4 col-sm-8 offset-md-2">
          Le déploiement des compteurs communicants est en cours. Vous n’êtes pas encore équipé d’un compteur communicant, ce qui ne vous permet pas d’être éligible à notre service Ma Conso Énergie. Ce service vous sera accessible dès l’installation d’un compteur communicant.
        </div>

        <!-- END references-edl -->
      </div>


      <div ngModelGroup="UserInfo" id="UserInfo">
        <section class="personal-infos">
          <section class="personal-infos-title row">
            <div class="col-sm-8 offset-md-2">
              <label class="form-label big">Mes informations personnelles :</label>
            </div>
          </section>

          <section class="field-lastname row">
            <div class="col-sm-2 form-label-wrapper">
              <label for="LastName" class="form-label" *ngIf="registrationModel.userType!==UserTypes.Pro">Nom</label>
              <label for="LastName" class="form-label" *ngIf="registrationModel.userType===UserTypes.Pro">Raison sociale</label>
            </div>
            <div class="col-sm-8 field-with-tooltip">
              <input id="LastName" class="form-control readonly-fields" type="text" name="LastName" ngModel readonly>
            </div>
          </section>
          <section class="field-firstname row" [hidden]="registrationModel.userType===UserTypes.Pro">
            <div class="col-sm-2 form-label-wrapper">
              <label for="FirstName" class="form-label">Prénom</label>
            </div>
            <div class="col-sm-8 field-with-tooltip">
              <input id="FirstName" class="form-control readonly-fields" type="text" name="FirstName" ngModel readonly>
            </div>
          </section>
          <section class="row">
            <div class="col-sm-2 form-label-wrapper label-address">
              <label for="AddressNumber" class="form-label" >Numéro de rue</label>
            </div>
            <div class="col-sm-3 input-address">
              <input id="AddressNumber"  class="form-control readonly-fields"  type="text" name="AddressNumber" ngModel readonly>
            </div>
            <div class="col-sm-2 form-label-wrapper label-address">
              <label for="Street" class="form-label" >Nom de la voie</label>
            </div>
            <div class="col-sm-3 input-address">
              <input id="Street" class="form-control readonly-fields" type="text" name="Street" ngModel readonly>
            </div>
          </section>
          <section class="row">
            <div class="col-sm-2 form-label-wrapper label-address">
              <label for="PostCode" class="form-label" >Code Postal</label>
            </div>
            <div class="col-sm-3 input-address">
              <input id="PostCode"  class="form-control readonly-fields"  type="text" name="PostCode" ngModel readonly>
            </div>
            <div class="col-sm-2 form-label-wrapper label-address">
              <label for="City" class="form-label " >Commune</label>
            </div>
            <div class="col-sm-3 input-address">
              <input id="City" class="form-control readonly-fields" type="text" name="City" ngModel readonly>
            </div>
          </section>
          <section class="row">
            <div class="col-sm-2 col-lg-2 form-label-wrapper">
              <label for="Email" class="form-label" >Email<span class="required">*</span> </label>
            </div>
            <div class="col-sm-8">
              <input id="Email" class="form-control" [ngClass]="((Email.touched || referencesFoundInEfluid) && Email.invalid)? 'is-invalid':''"
                     type="email"  name="Email" #Email="ngModel"
                     [(ngModel)]="registrationModel.userInfo.email" pattern='^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$' notEmptyValidator>
              <div *ngIf="Email.touched && Email.invalid" class="error-msg fst-italic">
                L'email est invalide.
              </div>
            </div>
          </section>
          <section class="row">
            <div class="col-sm-2 col-lg-2 form-label-wrapper">
              <label for="PhoneNumber" class="form-label" > Téléphone mobile<span class="required">*</span>  </label>
            </div>
            <div class="col-sm-8">
              <input id="PhoneNumber" class="form-control" [ngClass]="((Phone.touched || referencesFoundInEfluid) && Phone.invalid)? 'is-invalid':''" type="text" name="PhoneNumber"  pattern="((^[+][3]{2}[1-9]{1}[0-9]{8}$)|(^[0][0-9]{9}$))"
                     [(ngModel)]="registrationModel.userInfo.phoneNumber" #Phone="ngModel" placeholder="0612345678" required>
              <div *ngIf="Phone.touched && Phone.invalid" class="error-msg fst-italic">
                Le numéro de téléphone est invalide.
              </div>
            </div>
          </section>
          <section class="row">
            <div class="col-sm-8 offset-md-2" *ngIf="referencesFoundInEfluid">
              <p class="fw-light fst-italic"> Si les informations précisées dans ce formulaire
                <span *ngIf="registrationModel.userType !== UserTypes.Pro">(nom, prénom, adresse)</span>
                <span *ngIf="registrationModel.userType === UserTypes.Pro">(Raison sociale, adresse)</span>
                ne sont pas correctes ou plus à jour, merci de contacter
                notre service commercial au 03.89.24.60.60 afin de les mettre à jour.</p>
            </div>
          </section>

        </section>
        <!-- END personal-infos -->

        <!-- TODO : remove the ngIf on 'energiesOptions-section' when gas available-->
        <section *ngIf="elecAvailable === true" class="row-energies row">
          <div class="col-sm-2 form-label-wrapper">
            <label class="form-label">Sélectionnez la ou les énergies que vous utilisez <span class="required">*</span></label>
          </div>
          <div id="energies" class="col-sm d-flex">
            <section class="energy energy-electricity" *ngIf="elecAvailable === true">
              <label for="electricity" class="energy-wrapper selected">
                <div class="energy-logo color-elec">
                  <img class="img-fluid" src="assets/elec.svg" alt="elec-picto">
                </div>
                <span class="form-check-label" >Electricité</span>
                <input id="electricity" class="form-check-input" [ngClass]="Energies.invalid? 'border-danger':'border-primary'"
                       type="radio" name="Energies" #Energies="ngModel" [value]="EnergyType.Electricity"
                       [(ngModel)]="registrationModel.userInfo.energies" (ngModelChange)="onEnergiesChanges($event)" required>
              </label>
            </section>

            <!-- TODO: uncomment when gas option is activated -->
            <!-- <section class="energy energy-gas" *ngIf="gasAvailable !== false">
              <label for="gas" class="energy-wrapper">
                <div class="energy-logo color-gas">
                  <img class="img-fluid" src="assets/gas.svg" alt="gas-picto">
                </div>
                <span class="form-check-label" >Gas</span>
                <input id="gas"  class="form-check-input" [ngClass]="Energies.invalid? 'border-danger':'border-primary'"
                       type="radio" name="Energies" #Energies="ngModel" [value]="EnergyType.Gas"
                       [(ngModel)]="registrationModel.userInfo.energies" (ngModelChange)="onEnergiesChanges($event)" required>
              </label>
            </section> -->

            <!-- TODO: uncomment when gas option is activated -->
            <!-- <section class="energy energy-gas-electricity" *ngIf="gasAvailable!== false && elecAvailable != false">
              <label for="gas-electricity" class="energy-wrapper">
                <div class="energy-logos">
                  <span class="energy-logo color-elec"><img class="img-fluid" src="assets/elec.svg" alt="both-energies-picto" /></span>
                  <span class="energy-plus"><img class="img-fluid" src="assets/plus.svg" /></span>
                  <span class="energy-logo color-gas"><img class="img-fluid" src="assets/gas.svg" alt="both-energies-picto" /></span>
                </div>
                <span class="form-check-label" >Electricité + Gaz</span>
                <input id="gas-electricity" class="form-check-input" [ngClass]="Energies.invalid? 'border-danger':'border-primary'"
                       type="radio" name="Energies" #Energies="ngModel" [value]="EnergyType.Both"
                       [(ngModel)]="registrationModel.userInfo.energies" (ngModelChange)="onEnergiesChanges($event)" required>
              </label>
            </section> -->

          </div>
        </section>
        <!-- END energie-selection -->
      </div>


      <section id="conditions" class="position-relative form-part-to-disabled">

<!--        TODO change condition on errorInEnergies and errorSmartMeter when gas available-->
        <div class="col-sm-8 offset-md-2" *ngIf="referencesFoundInEfluid && !errorInReferences && !errorInEnergies && !errorSmartMeter">

            <app-elec-consent *ngIf="(registrationModel.userInfo.energies === EnergyType.Electricity
                              || registrationModel.userInfo.energies === EnergyType.Both) && registrationModel.userType !== undefined"
                              [userType]="registrationModel.userType!"
                              (electricityConsentChange)="registrationModel.electricityConsentModel=$event"
                              [(resetElecConsent)]="elecConsentResetNeeded"></app-elec-consent>

            <!-- TODO: uncomment when gas option is activated -->
            <!-- <app-gas-consent *ngIf="(registrationModel.userInfo.energies === EnergyType.Gas
                             || registrationModel.userInfo.energies === EnergyType.Both)"
                             (gasConsentChange)="registrationModel.gasConsentModel=$event"
                             [(resetGasConsent)]="gasConsentResetNeeded"></app-gas-consent> -->

            <app-rgpd-consent
            (rgpdConsentChange)="registrationModel.rgpdConsentModel=$event"></app-rgpd-consent>
        </div>

        <div class="btn-actions text-center mt-5">
          <!--          TODO adapt disbale ccndition when gas-->
          <button class="btn bg-primary" type="submit" [disabled]="!registerForm.valid || !referencesFoundInEfluid || errorInEnergies || errorSmartMeter"> Souscrire</button>
          <button class="btn bg-primary" type="button" (click)="OnFormReset()" > Effacer</button>
        </div>
      </section>

    </div>
      <div class="col-sm-8 offset-md-2 legend-wrapper mt-5">
        <p class="legend"><span class="legend">*</span> Champs obligatoires</p>
      </div>
  </form>



</div>


