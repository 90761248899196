
<footer id="footer" class="bg-primary" role="footer">
  <div class="container">
    <div class="row">
      <div class="col-12 mx-auto">
        <h2>2 agences pour vous accueillir</h2>
      </div>
    </div>
    <div class="row agencies">
      <div class="col-10 col-md-6 mx-auto">
        <h3>AGENCE DE COLMAR (SIÈGE SOCIAL)</h3>
        <p>10 rue des Bonnes Gens - 68000 Colmar<br />
        Du lundi au vendredi de 8h30 à 12h et de 13h30 à 17h.</p>
      </div>
      <div class="col-10 mx-auto col-md-6 col-right">
        <h3>AGENCE DE NEUF-BRISACH</h3>
        <p>2 rue Michel Marsal - 68600 Neuf-Brisach<br />
          Du lundi au vendredi de 8h à 12h et de 14h à 17h.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="btn-actions">
            <a class="text-decoration-none btn btn-white btn-lg contactLink" target="_blank" href="https://energies.vialis.net/contact-energies">Nous écrire</a>
          <button type="button" class="btn btn-white btn-lg btn-phone">
            <svg id="Symbols" xmlns="http://www.w3.org/2000/svg" width="35.878" height="35.878" viewBox="0 0 35.878 35.878">
              <g id="_1_ico_Phone02">
                <path id="ico-phone" d="M33.885,24.916a22.69,22.69,0,0,1-7.116-1.136,2,2,0,0,0-2.023.488l-4.385,4.4A30.069,30.069,0,0,1,7.225,15.537l4.385-4.405A2,2,0,0,0,12.1,9.109a22.69,22.69,0,0,1-1.136-7.116A1.986,1.986,0,0,0,8.97,0H1.993A1.993,1.993,0,0,0,0,1.993,33.882,33.882,0,0,0,33.885,35.878a1.986,1.986,0,0,0,1.993-1.993V26.909A1.986,1.986,0,0,0,33.885,24.916ZM17.939,0V19.932l5.98-5.98H35.878V0Z" fill="#fff"/>
              </g>
            </svg>
            <a class="tel" href="tel:0389246060">03 89 24 60 60</a>
          </button>
        </div>
      </div>
    </div>
    <div class="row legalMentionWrapper mt-3">
      <div class="col-12 legalMention">
        <p class="fst-italic">L’énergie est notre avenir, économisons-la! </p>
      </div>
    </div>
  </div>
</footer>
