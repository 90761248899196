import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {RouterLink} from "@angular/router";
import {NgClass, NgIf, NgTemplateOutlet} from "@angular/common";
import {ControlContainer, FormsModule, NgForm} from "@angular/forms";
import {ElectricityConsentModel} from "../../../models/consents/ElectricityConsent.model";
import {UserTypes} from "../../../models/UserTypes.enum";
import {PopoverModule} from "ngx-bootstrap/popover";

@Component({
  selector: 'app-elec-consent',
  standalone: true,
  imports: [
    RouterLink,
    NgIf,
    FormsModule,
    NgTemplateOutlet,
    NgClass,
    PopoverModule
  ],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
  templateUrl: './elec-consent.component.html',
  styleUrl: './elec-consent.component.scss'
})
export class ConsentComponent implements OnChanges, OnInit {
  @Input('userType') userType!: UserTypes;
  @Output() electricityConsentChange = new EventEmitter<ElectricityConsentModel | undefined>()
  electricityConsent: ElectricityConsentModel = new ElectricityConsentModel();


  //receive the info when consent must be reset
  @Input() resetElecConsent?: boolean;
  //After resetting the consent, emit event for the parent to set boolean 'resetNeeded' to false
  @Output() resetElecConsentChange = new EventEmitter<boolean | null | undefined>();

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['resetElecConsent'] && this.resetElecConsent) {

      this.electricityConsent = new ElectricityConsentModel();
      //event to ensure the parent boolean 'resetNeeded' is set to false;
      //wait for the next tick to avoid error NG0100: Expression has changed after it was checked
      setTimeout(() => {
        this.electricityConsentChange.emit(this.electricityConsent);
        this.resetElecConsentChange.emit(false)
      });
    }
  }

  onBillableOptionChange() {
    this.electricityConsent.acceptOptionConditions = undefined;
    this.electricityConsent.hasBoxAndWifi = undefined;
    this.electricityConsent.wifiNearMeter = undefined;
    this.electricityConsent.hasCompatibleDevice = undefined;
    this.electricityConsentChange.emit(this.electricityConsent);
  }

  protected readonly UserTypes = UserTypes;

  ngOnInit(): void {
    this.electricityConsent = new ElectricityConsentModel();
  }


}
