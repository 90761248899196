<section class="rgpd-consent-section" id="rgpd-consent">

  <label class="form-label big">Conditions nécessaires à la souscription :</label>
  <div ngModelGroup="rgpd-consent-group">

<div class="from-group form-check">
  <input class="form-check-input"  [ngClass]="rgpdData.touched && rgpdData.invalid? 'border-danger':'border-primary'" type="checkbox"
         id="rgpdData" name="rgpdData" #rgpdData="ngModel"
         (ngModelChange)="rgpdConsentChange.emit(rgpdConsent)" [(ngModel)]="rgpdConsent.allowDataHandling" required>
  <label class="form-check-label" for="rgpdData">
    <span class="required">*</span> Je suis titulaire du/des contrat(s) de fourniture et j'autorise Vialis
    et ses sous-traitants <span class="d-inline">
          <button class="tooltip-button" type="button" [popover]="CGInfos" containerClass="tooltip-content" triggers="focus" placement="top">
            <img src="assets/infobulle.svg" alt="help-icon">
          </button>
        </span> à recevoir, traiter, analyser et conserver mes données personnelles d'identification,
    de consommation et de facturation d'électricité et de gaz pour le service "Ma Conso Énergie" pendant
    toute la durée d’utilisation du service.
  </label>
  <ng-template #CGInfos>
    <p>Informations sur les sous-traitants disponibles dans les CGV.</p>
  </ng-template>


</div>

<div class="from-group form-check py-2 mb-1">
  <input class="form-check-input" [ngClass]="conditionsGenerales.touched && conditionsGenerales.invalid? 'border-danger':'border-primary'" type="checkbox"
         id="conditionsGenerales" name="conditionsGenerales" #conditionsGenerales="ngModel"
         [(ngModel)]="rgpdConsent.acceptGeneralConditions" required (ngModelChange)="rgpdConsentChange.emit(rgpdConsent)">
  <label class="form-check-label" for="conditionsGenerales">
    <span class="required">*</span> Je reconnais avoir pris connaissance et accepter les <a class="fw-bold text-black" target="_blank" href="https://energies.vialis.net/sites/default/files/domain_vialis_energies/pdf/cgv-ma-conso-energie.pdf">conditions
    générales d'utilisation du service</a>.
  </label>
</div>
  </div>

</section>
