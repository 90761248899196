import {Injectable} from '@angular/core';
import {catchError, throwError} from "rxjs";
import {RegistrationModel} from "../models/RegistrationModel";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {ContractDto} from "../models/dto/ContractDto";
import {SubscriptionConverter} from "./SubscriptionConverter";
import {SubscribeErrorType} from "../models/dto/subscribe-error.type";

class SubscribeErrorApi{
constructor(public message:string, public errorType:SubscribeErrorType) {
}
}
@Injectable({
  providedIn: 'root'
})
export class SubscriptionFormService {

  private subscriptionConverter = new SubscriptionConverter();
  constructor(private http: HttpClient) {
  }

  public Register(registerInfoModel: RegistrationModel) {
    if(!registerInfoModel.isValid()) throw new Error('registration is invalid');

    let subscription = this.subscriptionConverter.ConvertToSubscriptionDto(registerInfoModel);

    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post(environment.apiUrl + "api/Contrats/Subscribe",JSON.stringify(subscription), {headers:headers})
      .pipe(catchError((err:{status:number,error:SubscribeErrorApi}) => {
        return throwError(()=> {
          return {status:err.status, errorType: err.error.errorType}
        });
    }));
  }



  public GetDataFromEFluid(contractRef: string, EDLRef:string) {
    return this.http.get<ContractDto>(environment.apiUrl + "api/Contrats/" + contractRef + "/" + EDLRef).pipe(
      catchError((err) => {
        let message="Oups, une erreur est survenue";
        return throwError(()=>{ return {status: err.status, message: message}});
      })
    )
  }


}
