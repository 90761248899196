import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {RGPDConsentModel} from "../../../models/consents/RGPDConsent.model";
import {ControlContainer, FormsModule, NgForm, ReactiveFormsModule} from "@angular/forms";
import {NgClass} from "@angular/common";
import {PopoverModule} from "ngx-bootstrap/popover";
import {GasConsentModel} from "../../../models/consents/GasConsent.model";

@Component({
  selector: 'app-rgpd-consent',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    NgClass,
    PopoverModule
  ],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
  templateUrl: './rgpd-consent.component.html',
  styleUrl: './rgpd-consent.component.scss'
})
export class RgpdConsentComponent implements OnInit {

  rgpdConsent = new RGPDConsentModel();
  @Output() rgpdConsentChange = new EventEmitter<RGPDConsentModel | undefined>()

   ngOnInit(): void {

    this.rgpdConsent = new RGPDConsentModel();
  }


}
