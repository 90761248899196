import {isNullOrEmptyOrUndefined} from "../shared/helpers";
import {EnergyType} from "./EnergyType";

export class UserInfoModel {

  constructor(public firstName?: string, public lastName?: string, public street?: string, public number?:string,
              public postCode?: string, public city?: string, public email?: string, public phoneNumber?: string,
              public energies?: EnergyType) {
  }

  isValid() {
    return !isNullOrEmptyOrUndefined(this.email)
      && !isNullOrEmptyOrUndefined(this.phoneNumber)
      && this.energies != undefined
  }

}
