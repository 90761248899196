import {UserTypes} from "./UserTypes.enum";
import {isNullOrEmptyOrUndefined} from "../shared/helpers";
import {UserInfoModel} from "./UserInfo.model";
import {ElectricityConsentModel} from "./consents/ElectricityConsent.model";
import {GasConsentModel} from "./consents/GasConsent.model";
import {RGPDConsentModel} from "./consents/RGPDConsent.model";

export class RegistrationModel {

  constructor(
    public userInfo: UserInfoModel = new UserInfoModel(),
    public userType?: UserTypes,
    public ContractReference?: string,
    public ReferenceEDL?: string,
    public electricityConsentModel?: ElectricityConsentModel,
    public rgpdConsentModel?:RGPDConsentModel,
    public gasConsentModel?: GasConsentModel) {
  }

  isValid(): boolean | undefined {
    return !isNullOrEmptyOrUndefined(this.ContractReference)
      && !isNullOrEmptyOrUndefined(this.ReferenceEDL)
      && this.userType !== null && this.userType !== undefined
      && this.userInfo.isValid()
      && this.rgpdConsentModel !== undefined
      && (this.electricityConsentModel !== undefined || this.gasConsentModel !== undefined)
      && this.userTypeSyncWithElecConsent();
  }

  private userTypeSyncWithElecConsent(): boolean {

    if (this.electricityConsentModel === null || this.electricityConsentModel === undefined) return true;

    return (this.userType === UserTypes.ChequeEnergie && this.electricityConsentModel.userIsEnergyCheckBeneficiary) ||
      (this.userType !== UserTypes.ChequeEnergie && !this.electricityConsentModel.userIsEnergyCheckBeneficiary)
  }

}
