import {RegistrationModel} from "../models/RegistrationModel";
import {SubscriptionDto} from "../models/dto/SubscriptionDto";
import {RequestedServiceDto} from "../models/dto/RequestedServiceDto";
import {ElectricityConsentModel} from "../models/consents/ElectricityConsent.model";
import {RequestedServiceTypeDto} from "../models/dto/RequestedServiceTypeDto";
import {EnergyTypeDto} from "../models/dto/EnergyTypeDto";
import {GasConsentModel} from "../models/consents/GasConsent.model";
import {UserInfoModel} from "../models/UserInfo.model";
import {TitulaireInfosDto} from "../models/dto/TitulaireInfosDto";

export class SubscriptionConverter{

  public ConvertToSubscriptionDto(registerInfoModel: RegistrationModel) {

    let services = this.GetServicesRequested(registerInfoModel)

    let genericConsent= registerInfoModel.rgpdConsentModel === undefined? false :
      registerInfoModel.rgpdConsentModel.acceptGeneralConditions && registerInfoModel.rgpdConsentModel.allowDataHandling;

    let titulaireInfos = this.GetTitulaireInfos(registerInfoModel.userInfo);

    let contratRef = registerInfoModel.ContractReference === undefined?"":registerInfoModel.ContractReference;
    let edlref = registerInfoModel.ReferenceEDL === undefined?"":registerInfoModel.ReferenceEDL;

    return new SubscriptionDto(services,genericConsent, titulaireInfos,contratRef, edlref);
  }
  private GetServicesRequested(registerInfoModel: RegistrationModel) {
    let services :RequestedServiceDto[]= [];

    if(registerInfoModel.electricityConsentModel)
      services.push(this.ConvertElecConsentToService(registerInfoModel.electricityConsentModel))


    //TODO Uncomment when gas
    // if(registerInfoModel.gasConsentModel)
    //   services.push(this.ConvertGasConsentToService(registerInfoModel.gasConsentModel));

    return services;

  }

  private ConvertElecConsentToService(electricityConsentModel: ElectricityConsentModel) {
    let serviceType =  electricityConsentModel.userIsEnergyCheckBeneficiary? RequestedServiceTypeDto.CheckEnergyBeneficiary :
      (electricityConsentModel.wantBillableOption?RequestedServiceTypeDto.ClassicWithERL : RequestedServiceTypeDto.ClassicWithoutERL);

    return new RequestedServiceDto(EnergyTypeDto.Electricity,serviceType,electricityConsentModel.activateLoadCurve)
  }

  private ConvertGasConsentToService(gasConsentModel:GasConsentModel){
    return new RequestedServiceDto(EnergyTypeDto.Gas,RequestedServiceTypeDto.ClassicWithoutERL)
  }
  private GetTitulaireInfos(userInfo: UserInfoModel) {
    let email = userInfo.email === undefined?"":userInfo.email;
    let phone = userInfo.phoneNumber === undefined?"":userInfo.phoneNumber;

    return new TitulaireInfosDto(email, phone);
  }
}
