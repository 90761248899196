import { Routes } from '@angular/router';
import {RegisterFormComponent} from "./pages/register-form/register-form.component";


const title = 'Ma Conso Énergie | Vialis'
export const routes: Routes = [
  {path:'', redirectTo:'souscription', pathMatch:'full'},
  {path:'souscription',component:RegisterFormComponent, title:title},
  { path: '**', redirectTo: 'souscription' }
];
