import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  constructor() {
  }

  private loadingSubject =
    new BehaviorSubject<boolean>(false);

  loading$ = this.loadingSubject.asObservable();
  private displayDelay: number = 700;
  private timeoutIds: number[] = [];
  loadingOn() {
    this.timeoutIds.push(setTimeout(() => this.loadingSubject.next(true), this.displayDelay));
  }
  loadingOff() {
    this.timeoutIds.forEach(tm=>clearTimeout(tm));
    this.timeoutIds = [];
    this.loadingSubject.next(false);
  }
}
